'use client'

import ViverseCard, { ViverseCardProps } from '@/components/ViverseCard'
import IconLikeBordered from '@/components/assets/icon_like_bordered.svg'
import IconLikeFilled from '@/components/assets/icon_like_filled.svg'
import { Owner, Room } from '@/services/cms'
import { cn } from '@/lib/utils'
import Link from 'next/link'
import { MouseEventHandler } from 'react'

export interface LikedWorldCardProps extends ViverseCardProps {
  title: string
  image: string
  owner: Owner
  isLiked?: boolean
  href: string
  isLive?: boolean
  liveCount?: number
  onClickLike?: MouseEventHandler
  hideLiked?: boolean
}

const ownerPlaceholder = {
  id: '',
  display_name: '',
  snapshot_url: '/images/creator-intro/avatar-2.png',
  head_icon_url: '/images/creator-intro/avatar-2.png',
  htc_account_id: '',
}

function LikedWorldCard({
  className,
  title = 'titletitletitletitletitletitletitletitle',
  image,
  owner = ownerPlaceholder,
  isLiked = false,
  isLive = false,
  liveCount = 0,
  // eslint-disable-next-line no-console
  onClickLike = () => console.log('[LikedWorldCard]: called onClickLike'),
  hideLiked = false,
  href,
  ...props
}: LikedWorldCardProps) {
  return (
    <ViverseCard className={cn('group', className)} {...props}>
      <ViverseCard.Content className="flex h-[144px] items-center align-middle 2xl:h-[180px]">
        {!hideLiked && (
          <div
            className="absolute right-3 top-[10px] z-10 size-6 opacity-0 transition-opacity duration-150 group-hover:opacity-100 2xl:size-7"
            onClick={onClickLike}
          >
            {isLiked ? <IconLikeFilled /> : <IconLikeBordered />}
          </div>
        )}
        <div
          style={{
            backgroundImage: `url(${image || '/images/room-default.png'})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: '50% 50%',
          }}
          className={'size-full transition-transform duration-300 hover:scale-110'}
        >
          <Link href={href} target="_blank" className="block size-full cursor-pointer" />
        </div>
        <div className="absolute bottom-0 left-0 flex w-full items-center bg-[#00000099] px-3 py-[5px] opacity-0 backdrop-blur transition-opacity duration-150 group-hover:opacity-100">
          <Link
            href={`/profile/${owner.htc_account_id}`}
            target="_blank"
            className="cursor-pointer"
          >
            <div className="size-[30px] rounded-full 2xl:size-12">
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img src={owner.head_icon_url} width="100%" height="100%" alt="" />
            </div>
          </Link>
          <div className="ml-3 max-w-44 2xl:max-w-60">
            <Link href={href} target="_blank" className="cursor-pointer">
              <p className="subheader-3 2xl:subheader-1 truncate">{title}</p>
            </Link>
            <Link
              href={`/profile/${owner.htc_account_id}`}
              target="_blank"
              className="cursor-pointer"
            >
              <p className="caption truncate text-left">{owner.display_name}</p>
            </Link>
          </div>
        </div>
        {isLive && (
          <div className="absolute left-[12px] top-[12px] flex items-center gap-[6px] rounded-full bg-[#00000099] px-[8px] py-[3px]">
            <div className="size-2 rounded-full bg-[#EC4141]" />
            <div className="text-[12px] font-medium tracking-[0.19px] text-vive-white">
              {`Live ${liveCount ? `- ${liveCount} online` : ''}`}
            </div>
          </div>
        )}
      </ViverseCard.Content>
    </ViverseCard>
  )
}

export default LikedWorldCard
